import {
  closeSupplierInterestConfirmationAnalytics,
  closedBookedSupplierPopupAnalytics,
  completedVenueConfirmationPopupAnalytics,
  completedVenueSourceStepAnalytics,
  confirmOrDenyInterestAnalytics,
  triggerSupplierInterestConfirmationAnalytics as openSupplierInterestConfirmationAnalytics,
  triggeredVenueConfirmationPopupAnalytics,
} from 'lib/venue-confirmation/analytics/actions';

export enum VenueConfirmAnalyticsActionTypes {
  SHOW_VENUE_SOURCE_STEP = '[Analytics] [Shortlist] Show discover venue modal',
  COMPLETED_VENUE_SOURCE_STEP = '[Analytics] [Shortlist] Completed discover venue modal',
  OPEN_SUPPLIER_INTEREST_CONFIRMATION = '[Analytics] [VenueConfirm] Open supplier interest confirmation modal',
  CONFIRM_OR_DENY_SUPPLIER_INTEREST = '[Analytics] [VenueConfirm] Confirmed or denied supplier interest',
  CLOSE_SUPPLIER_INTEREST_CONFIRMATION = '[Analytics] [VenueConfirm] Closed supplier interest confirmation modal',
  CLOSED_BOOKED_SUPPLIER_POPUP = '[Analytics] [VenueConfirm] Closed booked supplier popup',
  TRIGGERED_BOOKING_CONFIRMATION_POPUP = '[Analytics] [VenueConfirm] Triggered booking confirmation popup',
  COMPLETED_BOOKING_CONFIRMATION_POPUP = '[Analytics] [VenueConfirm] Completed booking confirmation popup',
}

export type CompletedVenueSourceStepAnalyticsAction = ReturnType<
  typeof completedVenueSourceStepAnalytics
>;

export type OpenSupplierInterestConfirmationAnalyticsAction = ReturnType<
  typeof openSupplierInterestConfirmationAnalytics
>;

export type ConfirmOrDenyInterestAnalyticsAction = ReturnType<
  typeof confirmOrDenyInterestAnalytics
>;

export type CloseSupplierInterestConfirmationAnalyticsAction = ReturnType<
  typeof closeSupplierInterestConfirmationAnalytics
>;

export type ClosedBookedSupplierPopupAnalyticsActionAction = ReturnType<
  typeof closedBookedSupplierPopupAnalytics
>;

export type TriggeredVenueConfirmationPopupAnalyticsAction = ReturnType<
  typeof triggeredVenueConfirmationPopupAnalytics
>;

export type CompletedVenueConfirmationPopupAnalyticsAction = ReturnType<
  typeof completedVenueConfirmationPopupAnalytics
>;

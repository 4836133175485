import { IUISupplier, Slug } from '@bridebook/toolbox/src/types';
import { TUIShortlistSupplier } from 'lib/types';
import { VenueConfirmAnalyticsActionTypes } from 'lib/venue-confirmation/analytics/action-types';

interface BookedSupplierPopupAnalyticsPayload {
  location: string;
  popupVersion: string;
  popupCTA: string;
  supplierCategory?: Slug;
  supplierId?: IUISupplier['id'];
  supplierName?: IUISupplier['name'];
}

export const showVenueSourceStepAnalytics = () => ({
  type: VenueConfirmAnalyticsActionTypes.SHOW_VENUE_SOURCE_STEP,
});

export const completedVenueSourceStepAnalytics = (discoverSource: string) => ({
  type: VenueConfirmAnalyticsActionTypes.COMPLETED_VENUE_SOURCE_STEP,
  payload: discoverSource,
});

export const triggerSupplierInterestConfirmationAnalytics = (supplierId: string) => ({
  type: VenueConfirmAnalyticsActionTypes.OPEN_SUPPLIER_INTEREST_CONFIRMATION,
  payload: supplierId,
});

export const confirmOrDenyInterestAnalytics = (
  isConfirmed: boolean,
  supplier: TUIShortlistSupplier,
) => ({
  type: VenueConfirmAnalyticsActionTypes.CONFIRM_OR_DENY_SUPPLIER_INTEREST,
  payload: { isConfirmed, supplier },
});

export const closeSupplierInterestConfirmationAnalytics = (supplier: TUIShortlistSupplier) => ({
  type: VenueConfirmAnalyticsActionTypes.CLOSE_SUPPLIER_INTEREST_CONFIRMATION,
  payload: supplier,
});

export const closedBookedSupplierPopupAnalytics = ({
  location,
  popupVersion,
  popupCTA,
  supplierCategory,
  supplierId,
  supplierName,
}: BookedSupplierPopupAnalyticsPayload) => ({
  type: VenueConfirmAnalyticsActionTypes.CLOSED_BOOKED_SUPPLIER_POPUP,
  payload: { location, popupVersion, popupCTA, supplierCategory, supplierId, supplierName },
});

export const triggeredVenueConfirmationPopupAnalytics = ({
  location,
  popupVersion,
  popupCTA,
  supplierCategory,
  supplierId,
  supplierName,
}: BookedSupplierPopupAnalyticsPayload) => ({
  type: VenueConfirmAnalyticsActionTypes.TRIGGERED_BOOKING_CONFIRMATION_POPUP,
  payload: { location, popupVersion, popupCTA, supplierCategory, supplierId, supplierName },
});

export const completedVenueConfirmationPopupAnalytics = ({
  location,
  popupVersion,
  popupCTA,
  supplierCategory,
  supplierId,
  supplierName,
}: BookedSupplierPopupAnalyticsPayload) => ({
  type: VenueConfirmAnalyticsActionTypes.COMPLETED_BOOKING_CONFIRMATION_POPUP,
  payload: { location, popupVersion, popupCTA, supplierCategory, supplierId, supplierName },
});

import { DocumentChangeType } from 'firebase/firestore';
import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, merge, of } from 'rxjs';
import { filter, map, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Weddings } from '@bridebook/models';
import { ISupplier as IWeedingSupplier } from '@bridebook/models/source/models/Weddings/Suppliers.types';
import { enrichSuppliers } from '@bridebook/toolbox/src/enrich-suppliers/enrich-suppliers';
import { serializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import {
  onRemovedSuppliersFromShortlist,
  onSuppliersShortlistedSuccess,
} from 'lib/shortlist/actions';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';

export const initShortlistListener = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType('INIT_SHORTLIST_LISTENER'),
    withLatestFrom(state$),
    filter(([, state]: [any, IApplicationState]) => Boolean(state.weddings.profile.id)),
    mergeMap(([, state]) =>
      Weddings._.getById(state.weddings.profile.id)
        .Suppliers.query(undefined, ['removed', 'modified', 'added'])
        .observe(false)
        .pipe(
          map((data) => values(data)),
          map((list) => list.map(({ event, snapshot }) => ({ event, data: snapshot.data() }))),
          mergeMap((list) => {
            const suppliersToEnriched = list
              .filter(isSupplierToEnrich)
              .map(({ data }) => serializeTimestamps(data));
            const suppliersRemoved = list.filter(isSupplierToRemove).map(({ data }) => data);
            const customSuppliers = list.filter(isCustomSupplier).map(({ data }) => data);

            const getSuppliersWithCustom = async () => {
              const enrichedSuppliers = await enrichSuppliers(suppliersToEnriched);
              return [...enrichedSuppliers, ...customSuppliers];
            };

            return merge(
              from(getSuppliersWithCustom()).pipe(map(onSuppliersShortlistedSuccess)),
              of(onRemovedSuppliersFromShortlist(suppliersRemoved)),
            );
          }),
          takeUntil(action$.pipe(ofType('STOP_SHORTLIST_LISTENER'))),
        ),
    ),
  );

type EventSnapshot = {
  event: DocumentChangeType | 'value';
  data: IWeedingSupplier;
};

const isSupplierToRemove = ({ event, data }: EventSnapshot) =>
  event === 'removed' || !data.shortlisted;

const isCustomSupplier = ({ data }: EventSnapshot) => data.customSupplier;

const isSupplierToEnrich = ({ event, data }: EventSnapshot) =>
  !isSupplierToRemove({ event, data }) && !isCustomSupplier({ event, data });

import { ofType } from 'redux-observable';
import { ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  ignoreElements,
  pluck,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { IUser } from '@bridebook/models/source/models/Users.types';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { AuthActionTypes } from 'lib/auth/action-types';
import { updateLocale } from 'lib/auth/utils/update-locale';
import { getI18n } from 'lib/i18n/getI18n';
import { setLanguageCookie } from 'lib/i18n/utils/set-language-cookie';
import { ActionWithPayload, IEpic } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import { withMarket } from 'lib/utils/operators/with-market';
import { removeMarketFromPath } from 'lib/utils/url';

export const updateUserLanguageEpic: IEpic = (action$, { state$ }) => {
  const userChangeSubject = new ReplaySubject<IUser>();
  /**
   Listen to user changes - if any occurs, push user to the user replay subject. This way we
   always have access to the latest user value, even ON_USER_LISTENER was emitted after
   ON_AUTH_CONTEXT_AND_CONDITIONAL_REDIRECTS_COMPLETED
   **/
  action$
    .pipe(
      ofType(UserActionTypes.ON_USER_LISTENER),
      pluck<ActionWithPayload<IUser>, IUser | null>('payload'),
      filter((user) => user !== null),
      distinctUntilChanged((prev, curr) => prev?.l10n.locale === curr?.l10n.locale),
      // Make sure we unsubscribe from the user listener when the user signs out
      takeUntil(action$.pipe(ofType(AuthActionTypes.ON_FIREBASE_SIGN_OUT))),
    )
    .subscribe((user) => userChangeSubject.next(user as IUser));

  return action$.pipe(
    ofType(AuthActionTypes.ON_AUTH_CONTEXT_AND_CONDITIONAL_REDIRECTS_COMPLETED),
    switchMap(() =>
      userChangeSubject.pipe(
        withMarket(state$),
        tap(({ input: user, market }) => {
          const lang = (user as IUser).l10n.locale;
          const i18n = getI18n();
          const validNamespaces = Object.keys(i18n.store.data[i18n.language] || {});

          setLanguageCookie(lang);

          (async () => {
            await i18n.changeLanguage(lang);
            await i18n.reloadResources([lang], validNamespaces);

            /**
             Changing the user language might change the market prefix. For example if the user is
             on French market with english language, the prefix is set to fr-en. If the user will
             no change the language to French, the prefix will be set to fr and we have to update
             route, otherwise we will end up on 404 page with fr-en/fr prefix in url.
             **/
            try {
              const path = window.location.pathname;
              const marketFromUrl = gazetteer.getMarketByURL(path);
              if (marketFromUrl.prefix !== market.prefix) {
                await updateLocale(market, removeMarketFromPath(path, marketFromUrl.prefix));
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
          })();
        }),
        ignoreElements(),
      ),
    ),
  );
};
